import axios from 'axios';
import { getCookie } from '../utils/manageCookie';

export const baseURL = 'https://api.rugsai.com';
export const siteBaseURL = 'http://localhost:3000';
// export const oid = '632972ce92345bab9868e5fe';

let apiClient = axios.create({
  baseURL,
  withCredentials: false,
  timeout: 30000,
  headers: {
    authorization: `Bearer ${getCookie('access_token')}`,
    Accept: 'application/json',
  },
  // validateStatus: (status) => {
  //   return status < 600; // Reject only if the status code is greater than or equal to 600
  // },
});

const setAuthHeader = () => {
  apiClient = axios.create({
    baseURL,
    withCredentials: false,
    timeout: 30000,
    headers: {
      authorization: `Bearer ${getCookie('access_token')}`,
    },
  });
};

// ---------------- Form Data axios configurations -----------
let formDataApiClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${getCookie('access_token')}`,
  },
  timeout: 60000,
});

const setFormDataAuthHeader = () => {
  formDataApiClient = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getCookie('access_token')}`,
    },
    timeout: 60000,
  });
};

// ------------------ User -----------------------

const signUp = (payload) => {
  return apiClient.post('/users/signup', payload);
};
const login = (payload) => {
  return apiClient.post('/users/login', payload);
};
const resetPassword = (token, payload) => {
  return apiClient.patch(`/users/reset_password/${token}`, payload);
};
// ---------------- Renew Accsess Token --------------
const renewAccesssToken = () => {
  return apiClient.get('/users/renew_token');
};

// ---------------- Sizes ------------------------
const getAllSizes = () => {
  return apiClient.get('/sizes');
};

const createNewSize = (payload) => {
  return apiClient.post('/sizes', payload);
};
// ---------------- Colors ------------------------
const getAllColors = () => {
  return apiClient.get('/colors');
};

const createNewColor = (payload) => {
  return apiClient.post('/colors', payload);
};

// ----------------- Design -----------

const createNewDesign = (payload) => {
  return formDataApiClient.post('/designs', payload);
};

const addImagesToDesign = (id, payload) => {
  return formDataApiClient.post(`designs/images/${id}`, payload);
};

const addSizeAndPriceToDesign = (id, payload) => {
  return apiClient.post(`/designs/prices/${id}`, payload);
};

const addVarientsToDesign = (id, payload) => {
  return apiClient.post(`/designs/variants/${id}`, payload);
};

const addRawFileToDesign = (id, payload) => {
  return formDataApiClient.patch(`/designs/raw/${id}`, payload);
};

const getAllDesigns = (limit = 1000, page = 1) => {
  return apiClient.get(`/designs?limit=${limit}&page=${page}`);
};

const getDesignDetails = (id) => {
  return apiClient.get(`/designs/${id}`);
};

const publishDesign = (id, payload) => {
  return apiClient.patch(`/designs/${id}`, payload);
};
// eslint-disable-next-line
export default {
  setAuthHeader,
  setFormDataAuthHeader,
  signUp,
  login,
  resetPassword,
  renewAccesssToken,
  getAllSizes,
  createNewSize,
  getAllColors,
  createNewColor,
  createNewDesign,
  addImagesToDesign,
  addSizeAndPriceToDesign,
  addVarientsToDesign,
  addRawFileToDesign,
  getAllDesigns,
  getDesignDetails,
  publishDesign,
};
