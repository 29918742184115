import React, { useContext, useEffect, useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import apiClient from '../../apiClient/apiClient';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '../../App';

const DesignCreatePage = () => {
  const navigate = useNavigate();
  const { setIsSnackbar, setSnackbarMgs } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);
  const [allColorLists, setAllColorLists] = useState([]);
  const [allSizeLists, setAllSizeLists] = useState([]);
  const [materialsLists] = useState([
    'wool',
    'silk',
    'wool_&_silk',
    'viscose',
    'viscose_&_wool',
    'bamboo_silk',
  ]);
  const [usesLists] = useState([
    'living_room',
    'bedroom',
    'dinig_room',
    'starecase',
    'children_/_kids_room',
    'outdoor',
    'gaming_room',
  ]);

  //  design All States
  const [designId, setDesignId] = useState('');
  const [designName, setDesignName] = useState('');
  const [qaulity, setQuality] = useState('');
  const [shape, setShape] = useState('');
  const [color, setColor] = useState('');
  const [noOfColor, setNoOfColor] = useState('');
  const [size, setSize] = useState('');
  const [material, setMaterial] = useState('');
  const [uses, setUses] = useState('');
  const [resolution, setResolution] = useState('');
  const [description, setDescription] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [thumbnail, setThumbnail] = useState(null);

  // ----- Preview Image ----
  const [prevImage, setPrevImage] = useState(null);

  const onImageChange = (event) => {
    setThumbnail(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setPrevImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const getSizes = async () => {
    try {
      const res = await apiClient.getAllSizes();
      console.log(res);
      setAllSizeLists(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getColors = async () => {
    try {
      const res = await apiClient.getAllColors();
      console.log(res);
      setAllColorLists(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const createDesign = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // const d = {};
    // d.name = designName;
    // d.design_id = designId;
    // d.description = description;
    // d.thumbnail = thumbnail;
    // d.qaulity = qaulity;
    // d.color = color;
    // d.no_of_color = noOfColor;
    // d.size = size;
    // d.shape = shape;
    // d.licence = licence;
    // d.uses = uses;
    // d.resolution = resolution;

    // console.log(d);

    const designField = new FormData();
    designField.append('design_code', designId);
    designField.append('name', designName);
    designField.append('description', description);
    designField.append('thumbnail', thumbnail);
    designField.append('quality', qaulity);
    designField.append('color', color);
    designField.append('no_of_colors', noOfColor);
    designField.append('size', size);
    designField.append('material', material);
    designField.append('shape', shape);
    designField.append('licence', 'default licence');
    designField.append('uses', uses);
    designField.append('resolution', resolution);
    designField.append('meta_description', metaDescription);
    designField.append('meta_keywords', metaKeywords);
    designField.append('is_published', 'false');

    try {
      const res = await apiClient.createNewDesign(designField);
      console.log(res);
      const id = res.data.design.id;
      // TODO: Add redirect editing page
      setSnackbarMgs({ type: 'success', message: res.data.message });
      setIsSnackbar(true);
      setIsLoading(false);
      navigate(`/designs/add_images_to_design/${id}`);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSizes();
    getColors();
  }, []);

  return (
    <div className="min-h-screen">
      <div className="max-w-[1440px] container mx-auto">
        <div className="flex gap-2 items-center p-5 bg-white">
          <Link
            to="/"
            className="font-medium text-gray-500 hover:text-violet-500"
          >
            Home
          </Link>
          <IoIosArrowForward size={20} className="text-gray-500" />
          <Link
            to="/designs"
            className="font-medium text-gray-500 hover:text-violet-500"
          >
            Designs
          </Link>
          <IoIosArrowForward size={20} className="text-gray-500" />
          <h3 className="text-black font-medium">Create Design</h3>
        </div>
      </div>
      <div className="max-w-[1440px] container mx-auto flex justify-center pt-5 px-5 mb-5">
        <div className="w-full md:w-[90%] lg:w-3/4 bg-white shadow-md rounded-sm border p-5">
          <h3 className="text-sm sm:text-base py-2 rounded-sm bg-violet-500 text-white font-medium text-center mb-5 md:mb-0">
            Fill All Details To Create Designs
          </h3>
          <div className="p-0 md:p-10">
            <form onSubmit={createDesign}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="flex flex-col gap-1">
                  <label className="text-sm md:text-sm">Design ID</label>
                  <input
                    required
                    type="text"
                    placeholder="Design ID"
                    onChange={(e) => setDesignId(e.target.value)}
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label className="text-sm md:text-sm">Name</label>
                  <input
                    required
                    type="text"
                    placeholder="Design Name"
                    onChange={(e) => setDesignName(e.target.value)}
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                  />
                </div>
              </div>

              {/* ------------ */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pt-5">
                <div className="flex flex-col gap-1">
                  <label htmlFor="qaulity" className="text-sm md:text-sm">
                    Choose Quality
                  </label>
                  <select
                    id="qaulity"
                    name="qaulity"
                    required
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    onChange={(e) => {
                      setQuality(e.target.value);
                    }}
                  >
                    <option value="">Select Quality</option>
                    <option value="aubussan">Aubussan</option>
                    <option value="hand_knotted">Hand knotted</option>
                    <option value="handloom">Handloom</option>
                    <option value="hand_tufted">Hand Tufted</option>
                    <option value="flateweave">Handwoovwn / flatweave</option>
                  </select>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="shape" className="text-sm md:text-sm">
                    Choose Shape
                  </label>
                  <select
                    id="shape"
                    name="shape"
                    required
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    onChange={(e) => {
                      setShape(e.target.value);
                    }}
                  >
                    <option value="">Select Shape</option>
                    <option value="oval">Oval</option>
                    <option value="rectangle">Rectangle</option>
                    <option value="round">Round</option>
                    <option value="runner">Runner</option>
                    <option value="square">Square</option>
                  </select>
                </div>
              </div>
              {/* ------ Color ------ */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pt-5">
                <div className="flex flex-col gap-1">
                  <label htmlFor="colors" className="text-sm md:text-sm">
                    Colors
                  </label>
                  <select
                    id="colors"
                    name="colors"
                    required
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    onChange={(e) => {
                      setColor(e.target.value);
                    }}
                  >
                    <option value="">Select Color</option>
                    {allColorLists.map((color) => (
                      <option
                        key={color.id}
                        value={color.id}
                        className="capitalize"
                      >
                        {color.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col gap-1">
                  <label className="text-sm md:text-sm">Number Of Colors</label>
                  <input
                    required
                    type="text"
                    placeholder="Number Of Colors"
                    onChange={(e) => setNoOfColor(e.target.value)}
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                  />
                </div>
              </div>
              {/* ----- Size ---- */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pt-5">
                <div className="flex flex-col gap-1">
                  <label htmlFor="size" className="text-sm md:text-sm">
                    Size
                  </label>
                  <select
                    id="size"
                    name="size"
                    required
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    onChange={(e) => {
                      setSize(e.target.value);
                    }}
                  >
                    <option value="">Select Size</option>
                    {allSizeLists.map((size) => (
                      <option key={size.id} value={size.id}>
                        {size.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* --------- Material-------- */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="material" className="text-sm md:text-sm">
                    Material
                  </label>
                  <select
                    id="material"
                    name="material"
                    required
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    onChange={(e) => {
                      setMaterial(e.target.value);
                    }}
                  >
                    <option value="">Select Material</option>
                    {materialsLists.map((mat, index) => (
                      <option key={index} value={mat}>
                        {mat.replace(/_/g, ' ')}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className="flex flex-col gap-1">
                  <label className="text-sm md:text-sm">Licence</label>
                  <input
                    required
                    type="text"
                    placeholder="License"
                    onChange={(e) => setLicence(e.target.value)}
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                  />
                </div> */}
              </div>
              {/* ---- Uses and Resolutions */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5 pt-5">
                <div className="flex flex-col gap-1">
                  <label htmlFor="uses" className="text-sm md:text-sm">
                    Uses
                  </label>
                  <select
                    id="uses"
                    name="uses"
                    required
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    onChange={(e) => {
                      setUses(e.target.value);
                      console.log(e.target.value);
                    }}
                  >
                    <option value="">Select Uses</option>
                    {usesLists.map((use, index) => (
                      <option key={index} value={use}>
                        {use.replace(/_/g, ' ')}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col gap-1">
                  <label className="text-sm md:text-sm">Resolution</label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Resolution"
                    onChange={(e) => setResolution(e.target.value)}
                    className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1 pt-5">
                <label className="text-sm md:text-sm">Description</label>
                <textarea
                  required
                  type="text"
                  placeholder="Description"
                  rows={5}
                  onChange={(e) => setDescription(e.target.value)}
                  className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                />
              </div>
              <div className="flex flex-col gap-1 pt-5">
                <label className="text-sm md:text-sm">Meta Keywords</label>
                <input
                  required
                  type="text"
                  placeholder="Enter Kewords"
                  onChange={(e) => setMetaKeywords(e.target.value)}
                  className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                />
              </div>
              <div className="flex flex-col gap-1 pt-5">
                <label className="text-sm md:text-sm">Meta Description</label>
                <textarea
                  required
                  type="text"
                  placeholder="Meta Description"
                  rows={5}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                />
              </div>
              <div className="flex flex-col gap-1 pt-5">
                <label className="text-sm md:text-sm">Thumbnail</label>
                <input
                  required
                  type="file"
                  accept="image/*"
                  onChange={onImageChange}
                  className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                />
                {prevImage && (
                  <img
                    alt="preview prevImage"
                    src={prevImage}
                    className="h-32 object-contain"
                  />
                )}
              </div>
              <div className="flex justify-center pt-5">
                <button
                  type="submit"
                  disabled={isLoading ? true : false}
                  className={`${
                    isLoading
                      ? 'bg-gray-400 text-black'
                      : 'bg-blue-800 text-white hover:bg-blue-500 hover:cursor-pointer'
                  } capitalize text-sm px-5 py-1.5 rounded-sm mt-5`}
                >
                  Create Design
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* ------- Loader ----- */}
      {isLoading && (
        <div className="fixed inset-0 bg-black/70 z-30 text-white">
          <div className="max-w-[1440px] container mx-auto flex justify-center items-center h-screen">
            <p>Loading.......</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesignCreatePage;
