import React, { useState } from 'react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const setForgotPassword = (event) => {
    event.preventDefault();
    console.log('Forget Password');
    console.log(email);
  };
  return (
    <div>
      <div className="max-w-[1440px] mx-auto container px-5 flex justify-center items-center h-screen">
        <div className="w-full md:w-1/2 shadow-sm rounded bg-white p-5">
          <form onSubmit={setForgotPassword}>
            <div className="flex flex-col gap-1">
              <label className="text-sm text-blue-400 font-medium mb-1">
                Email<span className="text-xs">*</span>
              </label>
              <input
                required
                type="email"
                placeholder="Enter Your Email"
                className="border border-blue-400 text-sm font-medium rounded py-1.5 px-3"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="pt-5 flex justify-center">
              <button
                type="submit"
                className="bg-violet-600 text-white rounded-sm px-5 py-1.5"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
