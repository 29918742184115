import React from 'react';

const ResetPassword = () => {
  return (
    <div>
      <div className="max-w-[1440px] mx-auto container px-5 flex justify-center -mt-20 md:-mt-5 items-center h-screen">
        <div className="w-full md:w-1/2 shadow-sm rounded bg-white p-5">
          <form className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
              <label className="text-xs text-violet-400 font-medium">
                New Password
              </label>
              <input
                required
                type="text"
                placeholder="Enter New Password"
                className="border border-violet-400 text-sm font-medium rounded py-1.5 px-3"
                // onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-xs text-violet-400 font-medium">
                Confirm New Password
              </label>
              <input
                required
                type="text"
                placeholder="Enter New Password"
                className="border border-violet-400 text-sm font-medium rounded py-1.5 px-3"
                // onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex justify-center">
              <button
                type="submit"
                className="bg-violet-600 text-white rounded-sm px-5 py-1.5"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
