import React from 'react';
import { AiFillHome } from 'react-icons/ai';
import {
  // MdDashboard,
  MdOutlineDesignServices,
  MdColorLens,
  MdOutlinePhotoSizeSelectSmall,
} from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

const SidebarPanel = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="px-5 py-3">
      <div className="flex flex-col gap-2 rounded">
        <div
          className={`${
            location.pathname === '/'
              ? 'bg-violet-600 text-white flex gap-3 items-center py-2 px-3 rounded-lg hover:cursor-pointer'
              : 'flex gap-3 items-center py-2 px-3 rounded-lg hover:bg-violet-100 hover:text-black hover:cursor-pointer'
          } `}
          onClick={() => navigate('/')}
        >
          <AiFillHome size={25} />
          <h3 className="text-sm font-medium">Home</h3>
        </div>

        <div
          className={`${
            location.pathname.split('/')[1] === 'designs'
              ? 'bg-violet-600 text-white flex gap-3 items-center py-2 px-3 rounded-lg hover:cursor-pointer'
              : 'flex gap-3 items-center py-2 px-3 rounded-lg hover:bg-violet-100 hover:text-black hover:cursor-pointer'
          } `}
          onClick={() => navigate('/designs')}
        >
          <MdOutlineDesignServices size={25} />
          <h3 className="text-sm font-medium">Designs</h3>
        </div>
        <div
          className={`${
            location.pathname === '/colors'
              ? 'bg-violet-600 text-white flex gap-3 items-center py-2 px-3 rounded-lg hover:cursor-pointer'
              : 'flex gap-3 items-center py-2 px-3 rounded-lg hover:bg-violet-100 hover:text-black hover:cursor-pointer'
          } `}
          onClick={() => navigate('/colors')}
        >
          <MdColorLens size={25} />
          <h3 className="text-sm font-medium">All Colors</h3>
        </div>
        <div
          className={`${
            location.pathname === '/sizes'
              ? 'bg-violet-600 text-white flex gap-3 items-center py-2 px-3 rounded-lg hover:cursor-pointer'
              : 'flex gap-3 items-center py-2 px-3 rounded-lg hover:bg-violet-100 hover:text-black hover:cursor-pointer'
          } `}
          onClick={() => navigate('/sizes')}
        >
          <MdOutlinePhotoSizeSelectSmall size={25} />
          <h3 className="text-sm font-medium">All Size</h3>
        </div>
      </div>
    </div>
  );
};

export default SidebarPanel;
