import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../../../apiClient/apiClient';
import { Context } from '../../../App';
import { setCookie } from '../../../utils/manageCookie';
// import { AiFillEye } from 'react-icons/ai';

const LoginForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { setIsLogin, setIsSnackbar, setSnackbarMgs } = useContext(Context);

  const login = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const res = await apiClient.login({ email, password });
      // console.log(res);
      const access_token = res.data.access_token;
      const refresh_token = res.data.refresh_token;
      setCookie('access_token', access_token);
      setCookie('refresh_token', refresh_token);
      apiClient.setAuthHeader();
      apiClient.setFormDataAuthHeader();
      setIsLogin(true);
      setSnackbarMgs({ type: 'success', message: 'Login Suceess' });
      setIsSnackbar(true);
      setLoading(false);
      navigate('/');
    } catch (err) {
      console.log(err);
      if (err.message) {
        setSnackbarMgs({ type: 'error', message: err.message });
        setIsSnackbar(true);
      }
      if (err?.response?.data?.message) {
        setSnackbarMgs({
          type: 'error',
          message: err?.response?.data?.message,
        });
        setIsSnackbar(true);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="text-center text-blue-700 text-xl md:text-3xl font-semibold">
        Login
      </h2>
      <div>
        <form className="flex flex-col my-8" onSubmit={login}>
          <label className="text-sm text-blue-400 font-medium mb-1">
            Email<span className="text-xs">*</span>
          </label>
          <input
            required
            type="email"
            placeholder="Enter Your Email"
            className="border border-blue-400 text-sm font-medium rounded py-1.5 px-3"
            onChange={(e) => setEmail(e.target.value)}
          />

          <label className="text-sm text-blue-400 font-medium mt-8 mb-1">
            Password<span className="text-xs">*</span>
          </label>

          <input
            required
            type="password"
            placeholder="Enter Your Password"
            onChange={(e) => setPassword(e.target.value)}
            className="border border-blue-400 text-sm font-medium rounded py-1.5 px-3"
          />

          <div className="flex gap-1 items-center mt-3 mb-1">
            <input
              type="checkbox"
              className="border border-blue-400 h-3 w-3 rounded-sm cursor-pointer"
            />
            <label className="text-sm text-blue-400 font-medium">
              show password
            </label>
          </div>

          <div className="flex justify-end">
            <Link
              to="/auth/forgot_password"
              className="inline-block font-medium mt-3 text-sm text-blue-500 cursor-pointer"
            >
              Forgot Password?
            </Link>
          </div>

          <button
            type="submit"
            disabled={loading ? true : false}
            className={`${
              loading ? 'bg-gray-300 text-gray-600' : 'bg-blue-700 text-white'
            } block mt-8 py-2.5 text-lg font-semibold rounded-3xl`}
          >
            {loading ? 'Loging in...' : 'Log in'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
