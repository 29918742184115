import React, { useEffect, useState } from 'react';
// import { IoIosArrowForward } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../../apiClient/apiClient';

import { BiSolidPencil } from 'react-icons/bi';
import rupeeConvertor from '../../utils/rupeeConvertor';

const Designs = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [designs, setDesigns] = useState([]);

  const getDesignsList = async () => {
    setIsLoading(true);
    try {
      const res = await apiClient.getAllDesigns();
      console.log(res);

      setDesigns(res.data);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDesignsList();
  }, []);

  return (
    <div className="min-h-screen">
      <div className="max-w-[1440px] container mx-auto px-5">
        {/* <div className="flex gap-2 items-center py-5">
          <Link
            to="/"
            className="font-medium text-gray-500 hover:text-violet-500"
          >
            Home
          </Link>
          <IoIosArrowForward size={20} className="text-gray-500" />
          <h3 className="text-black font-medium">Designs</h3>
        </div> */}
        <div className="flex justify-between items-center py-5">
          <h3 className="text-xl font-semibold">Designs</h3>
          <button
            className="bg-violet-600 text-white px-3 text-sm font-medium py-1.5 rounded-sm transition-all duration-300 hover:bg-violet-700"
            onClick={() => navigate('/designs/create_design')}
          >
            Create Design
          </button>
        </div>

        {designs.length > 0 && (
          <div className="mt-2 mb-5">
            <div className="bg-white grid grid-cols-8 px-5 py-4 items-center gap-2 border rounded-sm">
              <p className="text-xs font-medium">Design Id</p>
              <p className="col-span-2 text-xs font-medium">Design Name</p>
              <p className="text-xs font-medium">Size</p>
              <p className="text-xs font-medium">Price</p>
              <p className="text-xs font-medium">Quality</p>
              <p className="text-xs font-medium">Total Color</p>
              <p className="text-xs font-medium"></p>
            </div>
            {designs.map((design) => (
              <div
                key={design.id}
                className="bg-white text-gray-600 grid grid-cols-8 px-5 py-2 items-center gap-2 border border-t-0 rounded-sm"
              >
                <p className="text-xs font-medium">{design.design_code}</p>
                <div className="col-span-2 flex gap-1 items-center">
                  <img
                    src={design.thumbnail}
                    alt={design.name}
                    className="h-10 w-10 object-contain p-1 rounded-full border"
                  />
                  <Link
                    to={`/designs/${design.id}`}
                    className={`${
                      design.is_published
                        ? 'transition-all duration-200 text-green-600 text-xs font-medium hover:text-blue-600'
                        : 'transition-all duration-200 text-xs font-medium hover:text-blue-600'
                    }`}
                  >
                    {design.name}
                  </Link>
                </div>
                <p className="uppercase text-xs font-medium">{design.size}</p>
                <p className="text-xs font-medium">
                  &#8377;{rupeeConvertor(design.price)}
                </p>
                <p className="capitalize text-xs font-medium">
                  {design.quality}
                </p>
                <p className="text-xs font-medium">{design.no_of_colors}</p>
                <div className="flex justify-end">
                  <BiSolidPencil
                    className="transition-all duration-200 hover:cursor-pointer hover:text-blue-600"
                    onClick={() =>
                      navigate(`/designs/update_design/${design.id}`)
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {isLoading && (
          <div className="fixed inset-0 bg-black/70 z-50 text-white">
            <div className="max-w-[1440px] container mx-auto flex justify-center items-center h-screen">
              <p>Loading.......</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Designs;
