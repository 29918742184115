import React, { useContext, useEffect, useState } from 'react';
import BasicListItemsCard from '../components/ui/Cards/BasicListItemsCard';
import { Context } from '../../src/App';
import { AiOutlineClose } from 'react-icons/ai';
import apiClient from '../apiClient/apiClient';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const { setIsSnackbar, setSnackbarMgs } = useContext(Context);

  // Size States
  const [sizesLists, setSizesLists] = useState([]);
  const [isOpenSizeDialog, setIsOpenSizeDialog] = useState(false);
  const [size, setSize] = useState({
    name: '',
    description: '',
  });

  // Color States
  const [colorsLists, setColorLists] = useState([]);
  const [isOpenColorDialog, setIsOpenColorDialog] = useState(false);
  const [color, setColor] = useState({
    name: '',
    description: '',
    code: '',
  });

  const getSizes = async () => {
    try {
      const res = await apiClient.getAllSizes();
      // console.log(res);
      setSizesLists(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getColors = async () => {
    try {
      const res = await apiClient.getAllColors();
      // console.log(res);
      setColorLists(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const createNewSize = async (event) => {
    event.preventDefault();
    try {
      const res = await apiClient.createNewSize(size);
      console.log(res);
      setIsSnackbar(true);
      setSnackbarMgs({
        type: 'success',
        message: res.data.message,
      });
      setIsOpenSizeDialog(false);
      getSizes();
    } catch (err) {
      console.log(err);
      setIsOpenSizeDialog(false);
    }
  };

  const createNewColor = async (event) => {
    event.preventDefault();
    try {
      const res = await apiClient.createNewColor(color);
      console.log(res);
      setIsSnackbar(true);
      setSnackbarMgs({
        type: 'success',
        message: res.data.message,
      });
      setIsOpenColorDialog(false);
      getColors();
    } catch (err) {
      console.log(err);
      setIsOpenSizeDialog(false);
    }
  };

  useEffect(() => {
    getSizes();
    getColors();
  }, []);

  return (
    <div>
      <div className="max-w-[1440px] container mx-auto px-5">
        <div className="flex justify-end">
          <button
            className="capitalize bg-blue-800 text-white text-sm px-5 py-1.5 rounded-sm mt-5 hover:bg-blue-500 hover:cursor-pointer"
            onClick={() => navigate('/designs/create_design')}
          >
            Create Design
          </button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4 lg:gap-5 py-10">
          <BasicListItemsCard
            title="Sizes"
            btnTitle="create new"
            createNew={() => setIsOpenSizeDialog(true)}
          >
            {sizesLists.length > 0 && (
              <ul className="list-disc p-5">
                {sizesLists.map((size) => (
                  <li key={size.id} className="font-medium text-lg">
                    {size.name}
                  </li>
                ))}
              </ul>
            )}
          </BasicListItemsCard>
          {/* ----- Color ------- */}
          <BasicListItemsCard
            title="Colors"
            btnTitle="create new"
            createNew={() => setIsOpenColorDialog(true)}
          >
            {colorsLists.length > 0 && (
              <div>
                {colorsLists.map((color) => (
                  <div key={color.id} className="flex gap-2 items-center">
                    <div className={`bg-[${color.code}] h-4 w-4`}></div>
                    <p className="capitalize font-medium text-lg">
                      {color.name}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </BasicListItemsCard>
        </div>
      </div>
      {/* ------------------- Size Dialog Box ----------------- */}
      {isOpenSizeDialog && (
        <div className="bg-black/60 fixed inset-0 z-40">
          <div className="max-w-[1440px] container mx-auto px-5 flex justify-center items-center h-screen">
            <div className="w-full md:w-1/2 -mt-20 sm:mt-0">
              <div className="flex justify-end pb-5">
                <div
                  className="rounded-full text-white flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    setIsOpenSizeDialog(false);
                  }}
                >
                  <AiOutlineClose size={30} />
                </div>
              </div>
              <div className="shadow bg-white p-5 rounded-sm">
                <h3 className="text-center text-xl text-blue-600 font-medium">
                  Create Size
                </h3>
                <form
                  onSubmit={createNewSize}
                  className="py-10 flex flex-col gap-5"
                >
                  <div className="flex flex-col gap-1">
                    <label className="text-sm md:text-sm">Name</label>
                    <input
                      required
                      type="text"
                      placeholder="Size eg:(5X6)"
                      onChange={(e) =>
                        setSize((prevState) => ({
                          ...prevState,
                          name: e.target.value,
                        }))
                      }
                      className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-sm md:text-sm">Description</label>
                    <input
                      required
                      type="text"
                      placeholder="Description"
                      onChange={(e) =>
                        setSize((prevState) => ({
                          ...prevState,
                          description: e.target.value,
                        }))
                      }
                      className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    />
                  </div>
                  <div className="flex justify-center pt-10">
                    <button
                      type="submit"
                      className="bg-blue-600 text-white px-8 py-1.5 rounded-sm"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* ------------------- Color Dialog Box ----------------- */}
      {isOpenColorDialog && (
        <div className="bg-black/60 fixed inset-0 z-40">
          <div className="max-w-[1440px] container mx-auto px-5 flex justify-center items-center h-screen">
            <div className="w-full md:w-1/2 -mt-20 sm:mt-0">
              <div className="flex justify-end pb-5">
                <div
                  className="rounded-full text-white flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    setIsOpenColorDialog(false);
                  }}
                >
                  <AiOutlineClose size={30} />
                </div>
              </div>
              <div className="shadow bg-white p-5 rounded-sm">
                <h3 className="text-center text-xl text-blue-600 font-medium">
                  Create Color
                </h3>
                <form
                  onSubmit={createNewColor}
                  className="py-10 flex flex-col gap-5"
                >
                  <div className="flex flex-col gap-1">
                    <label className="text-sm md:text-sm">Name</label>
                    <input
                      required
                      type="text"
                      placeholder="Color Name"
                      onChange={(e) =>
                        setColor((prevState) => ({
                          ...prevState,
                          name: e.target.value,
                        }))
                      }
                      className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-sm md:text-sm">Description</label>
                    <input
                      required
                      type="text"
                      placeholder="Description"
                      onChange={(e) =>
                        setColor((prevState) => ({
                          ...prevState,
                          description: e.target.value,
                        }))
                      }
                      className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <label className="text-sm md:text-sm">Color Code</label>
                    <input
                      required
                      type="text"
                      placeholder="Color Code eg:#ffffff"
                      onChange={(e) =>
                        setColor((prevState) => ({
                          ...prevState,
                          code: e.target.value,
                        }))
                      }
                      className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                    />
                  </div>
                  <div className="flex justify-center pt-10">
                    <button
                      type="submit"
                      className="bg-blue-600 text-white px-8 py-1.5 rounded-sm"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
