import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Context } from '../App';

const PrivateRoutes = () => {
  const { isLogin } = useContext(Context);

  return isLogin ? <Outlet /> : <Navigate to="/auth/login" />;
};

export default PrivateRoutes;
