import React, { useContext, useEffect, useState } from 'react';
import apiClient from '../../apiClient/apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../App';

const AddVarientToDesign = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setIsSnackbar, setSnackbarMgs } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);
  const [allSizeLists, setAllSizeLists] = useState([]);
  const [varient, setVarient] = useState({});

  const getSizes = async () => {
    setIsLoading(true);
    try {
      const res = await apiClient.getAllSizes();
      setIsLoading(false);
      setAllSizeLists(res.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const addVarient = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const res = await apiClient.addVarientsToDesign(params.id, varient);
      console.log(res);
      setSnackbarMgs({
        type: 'success',
        message: res.data.message,
      });
      setIsSnackbar(true);
      setIsLoading(false);
      navigate('/designs', {
        replace: true,
      });
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSizes();
  }, []);

  return (
    <div className="min-h-screen">
      <div className="mx-auto px-5">
        <h3 className="text-xl font-semibold py-5">
          Add Size And Price To Design
        </h3>

        <form
          onSubmit={addVarient}
          className="flex flex-col gap-5 border p-5 bg-white rounded-md"
        >
          <div className="flex flex-col gap-1">
            <label htmlFor="size" className="text-sm md:text-sm">
              Size
            </label>
            <select
              id="size"
              name="size"
              required
              className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
              onChange={(e) => {
                setVarient((prev) => ({ ...prev, size: 1 * e.target.value }));
              }}
            >
              <option value="">Select Size</option>
              {allSizeLists.map((size) => (
                <option key={size.id} value={size.id}>
                  {size.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm md:text-sm">Price</label>
            <input
              type="number"
              required
              className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
              placeholder="Price for your design"
              onChange={(e) => {
                setVarient((prev) => ({
                  ...prev,
                  price: 100 * e.target.value,
                }));
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm md:text-sm">Discount</label>
            <input
              type="number"
              required
              className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
              placeholder="Discount for this design"
              onChange={(e) => {
                setVarient((prev) => ({
                  ...prev,
                  discount: 1 * e.target.value,
                }));
              }}
            />
          </div>

          <div className="flex justify-center">
            <button
              type="submit"
              className="transition-all duration-300 px-5 py-1 bg-violet-500 text-white hover:bg-violet-800 rounded-sm"
            >
              Add Price
            </button>
          </div>
        </form>
      </div>
      {/* ------- Loader ----- */}
      {isLoading && (
        <div className="fixed inset-0 bg-black/70 z-30 text-white">
          <div className="max-w-[1440px] container mx-auto flex justify-center items-center h-screen">
            <p>Loading.......</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddVarientToDesign;
