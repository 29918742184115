import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BiSolidPencil } from 'react-icons/bi';
import { IoAddOutline } from 'react-icons/io5';
import { AiOutlineClose } from 'react-icons/ai';
// import { MdKeyboardBackspace } from 'react-icons/md';
import apiClient from '../../apiClient/apiClient';
import { Context } from '../../App';
import rupeeConvertor from '../../utils/rupeeConvertor';

const DesignDetailsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { setIsSnackbar, setSnackbarMgs } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);

  const [designDetail, setDesignDetail] = useState({});
  const [allSizeLists, setAllSizeLists] = useState([]);

  const [isVarientModel, setIsVarientModel] = useState(false);
  const [varient, setVarient] = useState({});

  const [currentVarient, setCurrentVarient] = useState('');

  const [rawDesignPopup, setRawDesignPopup] = useState(false);
  const [rawDesign, setRawDesign] = useState(null);

  const [isImagesModel, setIsImagesModel] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [prevImages, setPrevImages] = useState([]);
  // 👇 files is not an array, but it's iterable, spread to get
  const files = fileList ? [...fileList] : [];

  const fetchDesignDetails = async () => {
    setIsLoading(true);
    try {
      const res = await apiClient.getDesignDetails(params.id);
      console.log(res);
      setDesignDetail(res.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      alert('Something Went Wrong');
    }
  };

  const publishDesign = async () => {
    setIsLoading(true);
    try {
      const res = await apiClient.publishDesign(params.id, {
        is_published: 'true',
      });
      console.log(res);
      setIsLoading(false);
      setIsSnackbar(true);
      setSnackbarMgs({
        type: 'success',
        message: 'Design published succesfully',
      });
      fetchDesignDetails();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      alert('Something Went Wrong');
    }
  };

  const unpublishDesign = async () => {
    setIsLoading(true);
    try {
      const res = await apiClient.publishDesign(params.id, {
        is_published: 'false',
      });
      console.log(res);
      setIsLoading(false);
      setIsSnackbar(true);
      setSnackbarMgs({
        type: 'success',
        message: 'Design unpublished succesfully',
      });
      fetchDesignDetails();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      alert('Something Went Wrong');
    }
  };
  const getSizes = async () => {
    setIsLoading(true);
    try {
      const res = await apiClient.getAllSizes();
      setIsLoading(false);
      setAllSizeLists(res.data);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const addVarient = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const res = await apiClient.addVarientsToDesign(params.id, varient);
      // console.log(res);
      setSnackbarMgs({
        type: 'success',
        message: res.data.message,
      });
      setIsSnackbar(true);
      setIsLoading(false);
      setIsVarientModel(false);
      fetchDesignDetails();
      setVarient({});
    } catch (err) {
      console.log(err);
      setIsVarientModel(false);
      setIsLoading(false);
    }
  };

  const uplaodRawFile = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = new FormData();
    data.append('raw_file', rawDesign);
    try {
      const res = await apiClient.addRawFileToDesign(currentVarient, data);
      console.log(res);
      setSnackbarMgs({ type: 'success', message: res?.data?.message });
      setIsSnackbar(true);
      setRawDesignPopup(false);
      setIsLoading(false);
      setCurrentVarient('');
      setRawDesign(null);
    } catch (err) {
      console.log(err);
      setSnackbarMgs({ type: 'error', message: 'Something went wrong' });
      setIsSnackbar(true);
      setRawDesignPopup(false);
      setIsLoading(false);
      setCurrentVarient('');
      setRawDesign(null);
    }
  };

  const handleFileChange = (event) => {
    setFileList(event.target.files);
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        setPrevImages((prevState) => [
          ...prevState,
          URL.createObjectURL(event.target.files[i]),
        ]);
      }
    }
  };

  const handleUploadClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!fileList) {
      setIsLoading(false);
      setSnackbarMgs({ type: 'error', message: 'Please upload some images' });
      setIsSnackbar(true);
      return;
    }

    // 👇 Create new FormData object and append files
    const data = new FormData();
    files.forEach((file) => {
      data.append('images', file);
    });

    // 👇 Uploading the files using the fetch API to the server
    try {
      const res = await apiClient.addImagesToDesign(params.id, data);
      // console.log(res);
      setIsLoading(false);
      setSnackbarMgs({ type: 'success', message: res.data.message });
      setIsSnackbar(true);
      setIsImagesModel(false);
      fetchDesignDetails();
      setFileList(null);
      setPrevImages([]);
    } catch (err) {
      console.log(err);
      setIsImagesModel(false);
      setIsLoading(false);
      alert('Something Went Wrong');
    }
  };

  useEffect(() => {
    getSizes();
  }, []);
  useEffect(() => {
    fetchDesignDetails();
    // eslint-disable-next-line
  }, [params.id]);

  return (
    <div>
      {designDetail && (
        <div className="max-w-[1440px] container mx-auto p-5">
          {/* <button onClick={() => navigate(-1)}>
            <MdKeyboardBackspace size={30} />
          </button> */}
          <div className="flex justify-between border-b border-b-gray-300 pb-1">
            <div>
              <p className="text-xs font-medium text-gray-400">Design ID</p>
              <h3 className="text-sm font-semibold text-gray-700">
                {designDetail.design_code}
              </h3>
            </div>
            <div className="flex gap-3 text-xs sm:text-sm">
              {!designDetail.is_published ? (
                <button
                  onClick={publishDesign}
                  className="transition-all duration-300 bg-blue-500 px-5 py-1 text-white rounded-sm hover:bg-blue-800"
                >
                  Publish
                </button>
              ) : (
                <button
                  onClick={unpublishDesign}
                  className="transition-all duration-300 bg-blue-500 px-5 py-1 text-white rounded-sm hover:bg-blue-800"
                >
                  Unpublish
                </button>
              )}
              <button
                className="transition-all duration-300 flex items-center gap-1 bg-gray-600 text-white px-5 py-1 rounded-sm hover:bg-gray-800"
                onClick={() => navigate(`/designs/update_design/${params.id}`)}
              >
                <span>Edit</span>
                <span>
                  <BiSolidPencil />
                </span>
              </button>
            </div>
          </div>
          <h1 className="text-lg font-medium text-gray-800 pt-5">
            {designDetail.name}
          </h1>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 py-5">
            <div className="order-2 lg:order-1">
              <div className="flex flex-col gap-2 py-3">
                <div className="flex justify-between bg-white p-5 rounded-md">
                  <h3 className="font-semibold">Quality</h3>
                  <p className="capitalize">
                    {designDetail.quality?.replace(/_/g, ' ')}
                  </p>
                </div>
                <div className="flex justify-between bg-white p-5 rounded-md">
                  <h3 className="font-semibold">Shape</h3>
                  <p className="capitalize">{designDetail.shape}</p>
                </div>
                <div className="flex justify-between bg-white p-5 rounded-md">
                  <h3 className="font-semibold">Material</h3>
                  <p className="capitalize">
                    {designDetail?.material?.replace(/_/g, ' ')}
                  </p>
                </div>
                <div className="flex justify-between bg-white p-5 rounded-md">
                  <h3 className="font-semibold">Color</h3>
                  <p className="capitalize">{designDetail.color_name}</p>
                </div>
                <div className="flex justify-between bg-white p-5 rounded-md">
                  <h3 className="font-semibold">Number Of Colors</h3>
                  <p>{designDetail.no_of_colors}</p>
                </div>
                <div className="flex justify-between bg-white p-5 rounded-md">
                  <h3 className="font-semibold">Resolution</h3>
                  <p>{designDetail.resolution}</p>
                </div>
                <div className="flex justify-between bg-white p-5 rounded-md">
                  <h3 className="font-semibold">Uses</h3>
                  <div>
                    {designDetail.uses?.map((use) => (
                      <p className="capitalize">{use?.replace(/_/g, ' ')}</p>
                    ))}
                  </div>
                </div>
                <div className="flex justify-between items-center pt-5 pb-2">
                  <h3 className="font-semibold pt-3"> All variants</h3>
                  <button
                    onClick={() => setIsVarientModel(true)}
                    className="transition-all duration-300 flex items-center gap-1 text-sm px-3 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-700 hover:cursor-pointer"
                  >
                    <IoAddOutline size={20} />
                    <span>Add</span>
                  </button>
                </div>
                {designDetail.all_variants?.length > 0 && (
                  <div className="flex flex-wrap gap-3">
                    {designDetail.all_variants?.map((varient) => (
                      <div
                        key={varient.id}
                        className="group p-5 bg-white rounded-md w-48 border border-transparent transition-all duration-200 hover:bg-blue-100 hover:cursor-pointer hover:border-white"
                      >
                        <p className="font-medium">{varient.size}</p>
                        <p className="text-sm">
                          RS. {rupeeConvertor(varient.price)}
                        </p>
                        <p className="text-sm">
                          Discount &nbsp;{varient.discount}%
                        </p>
                        <button
                          className="px-4 py-1 mt-3 text-center rounded-md text-xs font-medium bg-blue-100 border border-white transition-all duration-200 group-hover:bg-white hover:border-blue-100"
                          onClick={() => {
                            setCurrentVarient(varient.id);
                            setRawDesignPopup(true);
                            console.log(currentVarient);
                          }}
                        >
                          Add Raw Design
                        </button>
                      </div>
                    ))}
                    {/* <div className="bg-white flex justify-center items-center rounded-md w-48 hover:cursor-pointer">
                      <IoAddOutline
                        size={40}
                        className="text-blue-600 hover:text-blue-700"
                      />
                    </div> */}
                  </div>
                )}
                <div className="flex justify-between items-center pt-5 pb-2">
                  <h3 className="font-semibold pt-3"> All Images</h3>
                  <button
                    onClick={() => setIsImagesModel(true)}
                    className="transition-all duration-300 flex items-center gap-1 text-sm px-3 py-1 bg-blue-500 text-white rounded-sm hover:bg-blue-700 hover:cursor-pointer"
                  >
                    <IoAddOutline size={20} />
                    <span>Add</span>
                  </button>
                </div>
                {designDetail.images?.length > 0 && (
                  <div className="flex flex-wrap gap-3 bg-white p-2">
                    {designDetail.images?.map((img, i) => (
                      <div key={i} className="rounded-md w-32">
                        <img src={img} alt={designDetail.name} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-center lg:justify-end w-full order-1 lg:order-2">
              <img
                className="h-auto w-auto md:h-96 object-contain border"
                src={designDetail.thumbnail}
                alt={designDetail.name}
              />
            </div>
          </div>
          <p className="pt-2">{designDetail.description}</p>
        </div>
      )}
      {/* ---------- Add Varient Model ---------- */}
      {isVarientModel && (
        <div className="fixed inset-0 z-40 bg-black/70 flex justify-center items-center min-h-screen">
          <form
            onSubmit={addVarient}
            className="w-[98%] lg:w-[60%] flex flex-col gap-5 border p-5 bg-white rounded-md"
          >
            <div className="flex justify-end">
              <button onClick={() => setIsVarientModel(false)}>
                <AiOutlineClose size={30} />
              </button>
            </div>
            <h3 className="text-center text-blue-600 font-medium text-lg">
              Add New Varient
            </h3>
            <div className="flex flex-col gap-1">
              <label htmlFor="size" className="text-sm md:text-sm">
                Size
              </label>
              <select
                id="size"
                name="size"
                required
                className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                onChange={(e) => {
                  setVarient((prev) => ({ ...prev, size: 1 * e.target.value }));
                }}
              >
                <option value="">Select Size</option>
                {allSizeLists.map((size) => (
                  <option key={size.id} value={size.id}>
                    {size.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm md:text-sm">Price</label>
              <input
                type="number"
                required
                className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                placeholder="Price for your design"
                onChange={(e) => {
                  setVarient((prev) => ({
                    ...prev,
                    price: 100 * e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-sm md:text-sm">Discount</label>
              <input
                type="number"
                required
                className="text-sm px-4 py-2 border border-black rounded-sm outline-black focus:border-none focus:outline focus:outline-blue-500"
                placeholder="Discount for this design"
                onChange={(e) => {
                  setVarient((prev) => ({
                    ...prev,
                    discount: 1 * e.target.value,
                  }));
                }}
              />
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="transition-all duration-300 px-5 py-1 bg-violet-500 text-white hover:bg-violet-800 rounded-sm"
              >
                Add Price
              </button>
            </div>
          </form>
        </div>
      )}
      {/* ---------- Add Raw Design to a Varient */}
      {rawDesignPopup && (
        <div className="fixed inset-0 z-40 bg-black/70 flex justify-center items-center min-h-screen">
          <form
            onSubmit={uplaodRawFile}
            className="w-[98%] lg:w-[60%] flex flex-col gap-5 border p-5 bg-white rounded-md"
          >
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setCurrentVarient('');
                  setRawDesign(null);
                  setRawDesignPopup(false);
                }}
              >
                <AiOutlineClose size={30} />
              </button>
            </div>
            <h3 className="text-center text-blue-600 font-medium text-lg">
              Add Raw Design File
            </h3>

            <input
              required
              type="file"
              onChange={(e) => setRawDesign(e.target.files[0])}
              title="Upload raw design file"
              className="border hover:cursor-pointer"
            />
            <div className="flex justify-center">
              <button
                type="submit"
                className="transition-all duration-300 px-5 py-1 bg-violet-500 text-white hover:bg-violet-800 rounded-sm"
              >
                Uoload
              </button>
            </div>
          </form>
        </div>
      )}
      {/* ------ Add Images To Design Model --------- */}
      {isImagesModel && (
        <div className="fixed inset-0 z-40 bg-black/70 flex justify-center items-center min-h-screen">
          <div className="w-[98%] lg:w-[60%] bg-white shadow-md border p-5 rounded-md">
            <div className="flex justify-end">
              <button onClick={() => setIsImagesModel(false)}>
                <AiOutlineClose size={30} />
              </button>
            </div>
            <h3 className="text-center text-blue-600 font-medium text-lg py-3">
              Add Images To Design
            </h3>
            <form
              onSubmit={handleUploadClick}
              className="flex flex-col justify-center gap-3"
            >
              <input
                required
                type="file"
                onChange={handleFileChange}
                multiple
                accept="image/*"
                title="Please uplaod some images"
                className="border hover:cursor-pointer"
              />

              <div>
                <button
                  type="submit"
                  className="bg-violet-500 text-white px-5 py-1 rounded-sm hover:bg-violet-700 hover:cursor-pointer"
                >
                  Upload
                </button>
              </div>
            </form>

            {prevImages && (
              <ul className="flex flex-wrap gap-5 py-5">
                {prevImages.map((img, i) => (
                  <li key={i} className="border">
                    <img
                      alt="preview prevImage"
                      src={img}
                      className="h-32 object-contain"
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
      {isLoading && (
        <div className="fixed inset-0 bg-black/70 z-50 text-white">
          <div className="max-w-[1440px] container mx-auto flex justify-center items-center h-screen">
            <p>Loading.......</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesignDetailsPage;
