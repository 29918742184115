import React from 'react';

const BasicListItemsCard = ({
  children,
  title = 'Heading',
  btnTitle = 'create',
  createNew,
}) => {
  return (
    <div className="border rounded-md shadow p-5">
      <p className="text-xl font-semibold border-b-2 pb-2">{title}</p>
      {children}
      <button
        className="capitalize bg-blue-800 text-white text-sm px-5 py-1.5 rounded-sm mt-5 hover:bg-blue-500 hover:cursor-pointer"
        onClick={createNew}
      >
        {btnTitle}
      </button>
    </div>
  );
};

export default BasicListItemsCard;
