import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../App';
import apiClient from '../../apiClient/apiClient';

const AddImageToDesignPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setIsSnackbar, setSnackbarMgs } = useContext(Context);

  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState(null);
  const [prevImages, setPrevImages] = useState([]);
  // 👇 files is not an array, but it's iterable, spread to get
  const files = fileList ? [...fileList] : [];

  const handleFileChange = (event) => {
    setFileList(event.target.files);
    if (event.target.files && event.target.files[0]) {
      for (let i = 0; i < event.target.files.length; i++) {
        setPrevImages((prevState) => [
          ...prevState,
          URL.createObjectURL(event.target.files[i]),
        ]);
      }
    }
  };

  const handleUploadClick = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!fileList) {
      setIsLoading(false);
      setSnackbarMgs({ type: 'error', message: 'Please upload some images' });
      setIsSnackbar(true);
      return;
    }

    // 👇 Create new FormData object and append files
    const data = new FormData();
    files.forEach((file) => {
      data.append('images', file);
    });

    // 👇 Uploading the files using the fetch API to the server
    try {
      const res = await apiClient.addImagesToDesign(params.id, data);
      console.log(res);
      setIsLoading(false);
      setSnackbarMgs({ type: 'success', message: res.data.message });
      setIsSnackbar(true);
      setFileList([]);
      setPrevImages([]);
      navigate(`/designs/add_price_to_design/${params.id}`, {
        replace: true,
      });
    } catch (err) {
      console.log(err);
      setFileList([]);
      setPrevImages([]);
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen">
      <div className="mx-auto px-5">
        <h3 className="text-xl font-semibold py-5">Add Images To Design</h3>
        <div className="bg-white shadow-md border p-5 rounded-md">
          <form
            onSubmit={handleUploadClick}
            className="flex flex-col justify-center gap-3"
          >
            <input
              required
              type="file"
              onChange={handleFileChange}
              multiple
              accept="image/*"
              title="Please uplaod some images"
              className="border hover:cursor-pointer"
            />

            <div>
              <button
                type="submit"
                className="bg-violet-500 text-white px-5 py-1 rounded-sm hover:bg-violet-700 hover:cursor-pointer"
              >
                Upload
              </button>
            </div>
          </form>

          {prevImages && (
            <ul className="flex flex-wrap gap-5 py-5">
              {prevImages.map((img, i) => (
                <li key={i} className="border">
                  <img
                    alt="preview prevImage"
                    src={img}
                    className="h-32 object-contain"
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {/* ------- Loader ----- */}
      {isLoading && (
        <div className="fixed inset-0 bg-black/70 z-30 text-white">
          <div className="max-w-[1440px] container mx-auto flex justify-center items-center h-screen">
            <p>Loading.......</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddImageToDesignPage;
