import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../App';
import { MdAccountCircle } from 'react-icons/md';
import { deleteCookie } from '../../utils/manageCookie';
// import { MdOutlineMenu } from 'react-icons/md';
const Navbar = () => {
  const { isLogin, setIsLogin } = useContext(Context);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  return (
    <div className="sticky top-0 z-30 border-b bg-white">
      <div className="max-w-[1440px] container mx-auto flex justify-between items-center h-[60px] px-5">
        {/* <MdOutlineMenu
          size={25}
          className="block md:hidden"
          onClick={toggleSidebar}
        /> */}
        <Link to="/" className="font-semibold">
          <span>RugsAi</span>
          <span className="text-red-500">Admin</span>
        </Link>
        <div>
          {!isLogin ? (
            <Link
              to="/auth/login"
              className="bg-indigo-600 text-white px-5 py-2 font-semibold rounded"
            >
              Login
            </Link>
          ) : (
            <div className="relative">
              <MdAccountCircle
                size={30}
                onClick={() => setIsAccountOpen(!isAccountOpen)}
                className="hover:text-violet-500 hover:cursor-pointer"
              />
              {isAccountOpen && (
                <div className="absolute right-0 top-10 p-5 bg-white border rounded-md shadow-md z-40 w-32">
                  <p
                    className="hover:text-violet-700 hover:cursor-pointer"
                    onClick={() => {
                      deleteCookie('refresh_token');
                      deleteCookie('access_token');
                      setIsLogin(false);
                      setIsAccountOpen(false);
                    }}
                  >
                    Log out
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
