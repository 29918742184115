import { createContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import ResetPassword from './pages/auth/ResetPassword';
import ForgotPassword from './pages/auth/ForgotPassword';
import Colors from './pages/Colors';
import Sizes from './pages/Sizes';
import Designs from './pages/designs/Designs';
import DesignCreatePage from './pages/designs/DesignCreatePage';
import Error from './Error';
import Navbar from './components/app/Navbar';
import Snackbar from './components/app/Snackbar';
// import apiClient from './apiClient/apiClient';
import SidebarPanel from './components/app/SidebarPanel';
import { getCookie } from './utils/manageCookie';
import PrivateRoutes from './utils/PrivateRoutes';
import UpdateDesignPage from './pages/designs/UpdateDesignPage';
import DesignDetailsPage from './pages/designs/DesignDetailsPage';
import AddImageToDesignPage from './pages/designs/AddImageToDesignPage';
import AddVarientToDesign from './pages/designs/AddVarientToDesign';

export const Context = createContext();
function App() {
  const location = useLocation();

  const [redirectedPath, setRedirectedPath] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  const [isSnackbarShow, setIsSnackbar] = useState(false);
  const [SnackbarMsg, setSnackbarMgs] = useState({
    type: '',
    message: '',
  });
  const [accessToken] = useState(() => getCookie('access_token'));
  // const [refreshToken, setRefreshToken] = useState(() =>
  //   getCookie('refresh_token')
  // );

  // const renewToken = async () => {
  //   try {
  //     const res = await apiClient.renewAccesssToken();
  //     setCookie('access_token', res.data.access_token);
  //     console.log(res);
  //   } catch (err) {
  //     if (err.response.data.message) {
  //       setIsSnackbar(true);
  //       setSnackbarMgs({ type: 'error', message: err.response.data.message });
  //     }
  //   }
  // };

  useEffect(() => {
    if (isSnackbarShow) {
      setTimeout(() => {
        setIsSnackbar(false);
      }, 10000);
    }
  }, [isSnackbarShow]);

  useEffect(() => {
    // console.log(refreshToken);
    if (accessToken) {
      setIsLogin(true);
      // console.log(accessToken);
    }
  }, [accessToken]);

  useEffect(() => {
    setRedirectedPath(location.pathname);
    // console.log(location.pathname);
  }, []);

  return (
    <Context.Provider
      value={{ isLogin, setIsLogin, setIsSnackbar, setSnackbarMgs }}
    >
      <Navbar />
      {isSnackbarShow && (
        <Snackbar type={SnackbarMsg.type} message={SnackbarMsg.message} />
      )}
      <div className="flex gap-2 bg-violet-50">
        <div className="hidden lg:block sticky top-[60px] h-[calc(100vh-60px)] w-[250px] overflow-y-auto bg-white border-r">
          <SidebarPanel />
        </div>
        <div className="w-full">
          <Routes>
            {/* Here Goes Protected Routes */}
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Home />} />
              <Route path="/designs" element={<Designs />} />
              <Route path="/designs/:id" element={<DesignDetailsPage />} />
              <Route
                path="/designs/create_design"
                element={<DesignCreatePage />}
              />
              <Route
                path="/designs/add_images_to_design/:id"
                element={<AddImageToDesignPage />}
              />
              <Route
                path="/designs/add_price_to_design/:id"
                element={<AddVarientToDesign />}
              />
              <Route
                path="/designs/update_design/:id"
                element={<UpdateDesignPage />}
              />
              <Route path="/colors" element={<Colors />} />
              <Route path="/sizes" element={<Sizes />} />
            </Route>
            {/* Here Goes Non Protected Routes */}
            <Route
              path="/auth/login"
              element={
                isLogin && redirectedPath !== '/auth/login' ? (
                  <Navigate to={redirectedPath} />
                ) : (
                  <>{!isLogin ? <Login /> : <Navigate to="/" />}</>
                )
              }
            />
            <Route path="/auth/forgot_password" element={<ForgotPassword />} />
            <Route
              path="/auth/reset_password/:token"
              element={<ResetPassword />}
            />
            <Route path="*" element={<Error />} />
          </Routes>
        </div>
      </div>
      <footer className="bg-white border-t">
        <p className="text-sm font-medium text-center p-5 whitespace-nowrap">
          This site is made by&nbsp;
          <a
            href="https://inflection.org.in"
            target="_blank"
            rel="noreferrer"
            className="text-violet-600 hover:text-violet-700 hover:underline font-semibold"
          >
            Inflection ORG
          </a>
        </p>
      </footer>
    </Context.Provider>
  );
}

export default App;
