import React from 'react';
import LoginForm from '../../components/ui/forms/LoginForm';

const Login = () => {
  return (
    <div className="h-screen flex items-center">
      <div className="max-w-[1440px] container mx-auto flex justify-around px-5">
        <div className="hidden lg:block w-[30%]">
          <h3>Welcom TO RugsAI</h3>
        </div>

        <div className="w-full lg:w-[30%] shadow-lg p-8 bg-white/90 rounded">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
